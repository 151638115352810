import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
//import AboutProject from '../../../../../components/case-studies/AboutProject'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class HelloKitty extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <div className={'hello-kitty'}>
          <Landing
            title={'Tvorba bannerov pre Hello Kitty'}
            text={'Návrh pútavého banneru a atraktívneho billboardu pre predajňu Hello Kitty v Auparku, ktoré ihneď upútajú zrak cieľovej skupiny – detí a rodičov. Kvalitne spracované propagačné materiály podľa najnovších trendov pomáhajú šíriť povedomie o značke a prilákať nových zákazníkov.'}
            img={data.landingImage}
            what_we_done={[
              { text: 'tvorba banneru & billboardu', link: '/nase-prace/grafika/digital-print/hello-kitty/' },
            ]}
            noShadow={true}
            pageMode={true}
          />

          {/*<AboutProject
            text={'Spolupráca s Hello Kitty prebiehala nasledovne:'}
            list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
          />*/}

          <Reveal>
            <div className={'print-showcase'}>
              <div className={'container'}>
                <div className={'short-description-center'}>
                  <h2>Na detailoch záleží</h2>
                  <p className="description">Precíznosť v každej maličkosti</p>
                  <p className="description-smaller">Dokonalosť spočíva v detailoch. Na prvý pohľad jednoduchá tvorba loga ukrýva oveľa viac, ako sa zdá. Držať sa minimalizmu a pritom docieliť estetickú príťažlivosť – to je výzva pre každé grafické oddelenie.</p>
                  {/* <LinkBtn>
                    <TranslatedLink to={'/nase-prace/grafika/digital-print/hello-kitty'}>
                      Prejsť do detailu projektu
                    </TranslatedLink>
                  </LinkBtn> */}
                </div>

                <Img className={'mt-big'} alt={this.constructor.name} fluid={data.print.childImageSharp.fluid} /></div>
            </div>
          </Reveal>

          <Reveal>
            <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
              <ProjectImageLightBox sectionTitle="Galéria" bannerUrl={banner_url} screens={screens} title="letenky" />
            </div>
          </Reveal>

          {/*<ClientReference
            text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
            clientName={'David Kopecký'}
            clientRole={'Majiteľ Allriskslovakia'}
            clientPhoto={data.clientPhoto}
          />*/}

          <Footer
            title={'OurServices.Process'}
            subtitle={'OurServices.ProcessSubTitle'}
            link={'/o-nas/ako-pracujeme'}
            leftTopTitle='project'
            nextProjectName={'Hledej remeselníka'}
            nextProjectUrl={'/nase-prace/pripadove-studie/grafika/logo-branding/hledej-remeselnika/'}
          />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/hello_kitty/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/HelloKitty_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    print: file(relativePath: { eq: "case-studies/HelloKitty_print.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default HelloKitty
